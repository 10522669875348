.time-container {
  width: 650px;
  height: 133px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  border: 1px solid #E8FFE8;
  background: rgba(255, 255, 255, 0.09);
  margin-top: 140px;
  color: #4ED5E0;
  font-size: 120px;
  font-weight: bold;
  padding: 8px 32px;
  font-family: 'Inconsolata', monospace;
}


@media only screen and (max-width: 991px) {
  .time-container {
    width: 70vw;
    height: 15vw;
  }
  .time-container {
    font-size: 14vw;
    margin-top: 10vw;
  }
}