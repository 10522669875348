.home-page-time-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-Participating-input:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;

}

.shapes-card-mobile,
.purple-bubble-mobile{
  display: none;
}


.price-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 140px;
}


.view-all-Participating-text {
  display: none;
}



.mobile-Participating-title {
  display: none;
}

.price-section-winner {
  position: relative;
  background: linear-gradient(
    105.49deg,
    rgba(255, 255, 255, 0.06) -9.46%,
    rgba(255, 255, 255, 0.01) 113.7%
  );
  height: 471px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: -1;
}

.shapes-behind-card-desktop {
  position: absolute;
  right: 0%;
  z-index: 7;
  pointer-events: none;
  z-index: -1;
  filter: blur(15px);
}

.price-section-winner div {
  margin-inline-start: 25px;
  text-transform: uppercase;
}

.price-section-price-image img{
  width: 567px;
  height: 471px;
  /* height: 100%; */
  /* max-height: 471px; */
}

.price-section-winner-text {
  font-weight: 800;
  font-size: 50px;
  letter-spacing: 0.035em;
  text-align: left;
  font-family: "Mont-bold";
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-style: normal;
}

.price-section-winner-text div {
  margin-inline-start: 0px;
  text-transform: uppercase;
}

.price-section-winner-text-give-away {
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  text-transform: uppercase;
}
.price-section-winner-is {
  height: 60px;
  background: #fff;
  color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  letter-spacing: 0.02em;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-top: 22px;
  position: relative;
}

.price-section-winner-is div {
  text-transform: none;
  font-family: "Mont";
}

.price-section-winner-is::after {
  position: absolute;
  /* content: url("../../assets/SVG/price/Ellipse235.svg"); */
}

.price-section-winner-is div {
  padding-inline-start: 0px;
}

.Participating-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 141px;
  width: 889px;
  height: 513px;
  background: linear-gradient(
    105.49deg,
    rgba(255, 255, 255, 0.06) -9.46%,
    rgba(255, 255, 255, 0.01) 113.7%
  );
  backdrop-filter: blur(50px);

  border-radius: 39px;
}

.small-white-star {
  display: none;
}

.small-star {
  position: absolute;
  left: 0%;
  right: auto;
  top: -20px;
}

.big-star {
  position: absolute;
  right: -2%;
  left: auto;
  bottom: -15px;
}

.Participating-header {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 40px;
}

.Participating-title-style {
  font-size: 28px;
  color: #4ed5e0;
  letter-spacing: 0.035em;
  font-family: "Mont-bold";
}

.search-Participating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  height: 38px;
  background: linear-gradient(
    93.58deg,
    rgba(255, 255, 255, 0.3) -15.74%,
    rgba(255, 255, 255, 0.05) 140.31%
  );
  border-radius: 10px;
}

.search-Participating img {
  margin-inline-end: 10px;
  margin-inline-start: 20px;
}

.search-Participating-input {
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: "Mont";
  line-height: 0px;
}


.search-Participating-input input {
  padding-top: 5px;
}




.Participating-header div:nth-child(2) {
  margin-inline-start: 50px;
}

.Participating-User-Name-text {
  color: #ffffff;
  letter-spacing: 0.035em;
  font-size: 24px;
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-family: "Mont";
  width: 300px;
}

.Participating-users {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-inline-start: 50px;
  margin-inline-end: 40px;
}

.Participating-users::after{
  content: '';
  width: 350px;

}
.behind-users {
  position: absolute;
  left: -40%;
  right: auto;
  top: -50%;
  pointer-events: none;
}

.Participating-User-Name-text-border-mobile {
  display: none;
}

.view-all-Participating-desktop{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}

.view-all-Participating-desktop div {
  font-family: 'Mont';
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.035em;
  text-decoration-line: underline;
  color: #FFFFFF;
  opacity: 0.5;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .Participating-users {
    justify-content: flex-start;
    margin-inline-start: 1vw;
  }

  .mobile-Participating-title {
    display: block !important;
    font-weight: 600;
    letter-spacing: 0.1em;
    font-size: 5.5vw;
    margin-top: 5vw;
    position: relative;
  }

  .Participating-User-Name-text {
    font-size: 4vw;
    margin-bottom: 2vw;
    margin-top: 2vw;
    height: 9.5vw;
    display: flex;
    justify-content: flex-start;
  }
  .big-star {
    display: none;
  }
  .Participating-container {
    height: 65vw;
    margin-top: 5vw;
    text-align: center;
    border-radius: 10px;
    width: 90%;
  }

  .price-section-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 10vw;
  }

  .small-star {
    width: 27px;
    height: 25px;
    left: 8%;
    right: auto;
    top: -15px;
  }

  .price-section-price-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
  }

  .price-section-price-image img {
    width: 88vw;
    height: auto;
    border-radius: 10px;
  }

  .Participating-inner-container {
    height: 60vw;
    overflow: hidden;
    width: 97vw;
  }

  .Participating-title-style {
    display: none;
  }

  .search-Participating {
    width: 90%;
    display: flex;
    justify-content: flex-start;
  }

  .behind-users {
    display: none;
  }
  .search-Participating img {
    margin-inline-start: 5vw;
  }

  .Participating-header {
    margin-bottom: 10px;
    margin-top: 40px;
  }

  .Participating-header div:nth-child(2) {
    margin-inline-start: 0px;
  }

  .small-white-star {
    display: block;
    position: relative;
    right: auto;
    left: 73%;
    top: 60%;
  }

  .price-section-winner {
    width: 90%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    height: 45vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  .price-section-winner div {
    margin-inline-start: 0px;
  }

  .price-section-winner-is {
    background: linear-gradient(
      93.58deg,
      rgba(255, 255, 255, 0.3) -15.74%,
      rgba(255, 255, 255, 0.05) 140.31%
    );
    border-radius: 10px;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: 0.02em;
    height: 9vw;
    width: 100%;
  }

  .price-section-winner-is div {
    margin-inline-start: 20px;
  }

  .aaa {
    margin-inline-start: 0px !important;
    width: 80vw;
  }
  .price-section-winner-text-give-away,
  .price-section-winner-text,
  .price-section-winner-text div {
    text-transform: none !important;
  }
  .price-section-winner-text {
    text-align: center;
    font-family: "Mont-bold";
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    font-size: 8vw;
    text-transform: none;
  }

  .Participating-User-Name-text-border-mobile {
    display: block;
    border-bottom: 1px solid #292f3f;
    width: 90%;
    margin-inline-start: 3vw;
  }

  .view-all-Participating-text {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    font-weight: 600;
    font-size: 3vw;
    letter-spacing: 0.035em;
    color: #fff;
    opacity: 0.5;
    font-family: "Mont";
    height: 46px;
    margin: 15px 0px;
    text-decoration-line: underline;
  }

  .view-all-Participating-text div {
    cursor: pointer;
  }

  .Participating {
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .shapes-behind-card-desktop {
    display: none;
  }

  .search-Participating {
    height: 9vw;
  }

  .shapes-card-mobile {
    display: block;
    position: absolute;
    pointer-events: none;
    left: 0%;
    top: -100%;
    filter: blur(10px);
    width: 100%;
    z-index: -5;
  }

  .purple-bubble-mobile{
    display: block;
    position: absolute;
    pointer-events: none;
    left: -5%;
    opacity: 0.7;
  }

  .view-all-Participating-desktop{
    display: none;
  }
}
