.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 47px;
  position: relative;
}

.mobile-header {
  display: none;
}

.tabs-container {
  display: flex;
  align-items: center;
  margin-inline-end: 1%;
}

.tab-container {
  font-size: 15px;
  letter-spacing: 0.03em;
  color: #fff;
  opacity: 0.9;
  padding: 8px 10px;
  margin: 0px 10px;
  cursor: pointer;
}

.connect-button-container {
  font-size: 15px;
  color: #0f1223;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 6px;
  display: none;
}

.xpnet-giveaway-style {
  letter-spacing: 0.03em;
  opacity: 0.9;
  margin: 0px 4px;
  padding: 8px 10px;
  color: #fff;
  margin-inline-start: 1%;
}

.language-text {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-text img {
  margin-inline-end: 8px;
}

.menu-burger-header {
  display: none;
}

@media only screen and (max-width: 991px) {
  .tabs-container {
    display: none;
  }

  .close-mobile-popup{
    position: absolute;
    cursor: pointer;
  }
  .desktop {
    display: none;
  }
  .header-container {
    margin-top: 4vw;
  }
  .xpnet-giveaway-style {
    width: 60px;
    text-align: left;
    font-weight: 510;
    line-height: 126%;
  }
  .menu-burger-header {
    display: block;
    margin-inline-end: 5vw;
    cursor: pointer;
  }

  .mobile-header {
    display: flex;
    padding-top: 10vw;
    flex-direction: column;
    text-align: left;
    position: absolute;
    background: linear-gradient(
      31.18deg,
      rgba(37, 43, 56, 0.8) 21.04%,
      rgba(75, 86, 108, 0.5) 116.65%
    );
    border: 1px solid #3e4d6d;
    box-sizing: border-box;
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12px);
    border-radius: 35px;
    width: 100%;
    height: 55vw;
    top: -1.5%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .mobile-header .tab-container {
    padding: 8px 5%;
    height: 11vw;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 600;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }
}
