.footer-border {
  height: 0px;
  border: 1px solid #292f3f;
  margin-top: 200px; 
}

.flex-terms{
  display: flex;
  align-items: center;
}

/* footer {
  margin-top: 200px;
  display: flex;
  width: 100;
  align-items: center;
  justify-content: center;
} */


.footer-text{
display: none;
}
.footer-flex-xp {
  height: 58px;
  color: #a3b3ca;
  opacity: 0.5;
  font-size: 15px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-flex {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.footer-flex-xp{
  flex-direction: row-reverse;
  margin-inline-end: 2%;
}

.footer-flex-xp div:nth-child(1) {
  margin-inline-start: 25px;
}

.footer-flex-xp div:nth-child(2){
  margin-inline-start: 25px;
}

.footer-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-social{
  display: flex;
  margin-inline-start: 1%;
}

.footer-social img{
  margin: 0 3px;
}

@media only screen and (max-width: 991px){

  .footer-border{
    margin-top: 0px;
  }
  .footer-flex{
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: 5vw;    

  }
  .footer-flex-xp{
    display: flex;
    flex-direction: column-reverse;
    margin-top: 7vw;
    
  }

  .flex-terms{
    /* justify-content: flex-start; */
    margin-inline-start: -80px !important;
    
  }

  .footer-text{
    margin-inline-start: 0px !important;
  }

  .footer-continer{
    z-index: 0;
  }


  
}