.App {
  text-align: center;
  position: relative;
}

.wrapper {
  margin: 0 auto;
  max-width: 1350px;
}

@font-face {
  font-family: "Mont";
  src: local("Mont"),
    url("./assets/Fonts/mont/MontRegular.otf") format("truetype");
}

@font-face {
  font-family: "Mont-bold";
  src: local("Mont-bold"),
    url("./assets/Fonts/mont/MontBold.otf") format("truetype");
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

.box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #06d85f;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.button:hover {
  background: #06d85f;
}

.overlay,
.overlay-none {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup::after {
  content: url("./assets/SVG/desktopPop/smallGridStar.svg");
  position: absolute;
  top: 97.5%;
  left: 6%;
  right: auto;
}

.overlay-none {
  visibility: visible;
  opacity: 1;
}

.popup-desktop-title {
  font-size: 28px;
  letter-spacing: 0.035em;
  color: #4ed5e0;
  font-family: "Mont-bold";
  text-align: left;
  margin-top: 81px;
  position: relative;
}

.search-popup-desktop {
  width: 344px;
  height: 38px;
  background: linear-gradient(
    93.58deg,
    rgba(255, 255, 255, 0.3) -15.74%,
    rgba(255, 255, 255, 0.05) 140.31%
  );
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-inline-start: 30px;
}

.search-popup-desktop input {
  background: transparent;
  border: none;
  font-family: "Mont";
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 0.035em;
}

#nice-scroll-users::-webkit-scrollbar {
  width: 10px;
  border: 70px solid transparent;
}

/* Track */
#nice-scroll-users::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  width: 10px;
}

/* Handle */
#nice-scroll-users::-webkit-scrollbar-thumb {
  background: #5c6680;
  border-radius: 34px;
  width: 60px;
}

/* Handle on hover */
#nice-scroll-users::-webkit-scrollbar-thumb:hover {
  background: #5c6680;
}

#nice-scroll-users::-webkit-scrollbar-button {
  width: 60px;
}

.search-popup-desktop input:focus {
  outline: none;
}

.search-popup-desktop img {
  margin-inline-end: 10px;
}

.popup-users-bg {
  /* background: linear-gradient(
    105.49deg,
    rgba(255, 255, 255, 0.06) -9.46%,
    rgba(255, 255, 255, 0.01) 113.7%
  ); */
  /* backdrop-filter: blur(50px); */
  border-radius: 30px;
  /* margin-top: 42px; */
  /* display: flex; */
  /* align-items: center; */
  /* flex-wrap: wrap; */

  padding-inline-start: 70px;
  padding-inline-end: 70px;
  max-height: 350px;
  min-height: 350px;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-auto-rows: 50px;
  position: relative;
  overflow: auto;
}

.popup-desktop-title img {
  position: absolute;
  top: -8px;
  left: 37%;
  pointer-events: none;
}

.mid-grid-star {
  position: absolute;
  pointer-events: none;
  right: 13%;
  left: auto;
  top: 22.5%;
  z-index: 55;
}

.pop-up-users {
  width: 180px;
  /* height: 34px; */
  font-size: 21px;
  letter-spacing: 0.035em;
  color: #fff;
  font-family: "Mont";
  text-align: left;
  margin-bottom: 10px;
}

.close-popup-button {
  width: 42px;
  height: 42px;
  background: #1e222b;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  left: auto;
  right: 2%;
  top: 2%;
}

.popup {
  margin: 70px auto;
  /* padding: 20px; */
  border-radius: 35px;
  /* border: 2px solid #3E4D6D; */

  /* width: 30%; */
  width: 801px;
  height: 800px;
  position: relative;
}

.popup h2 {
  margin-top: 0;
  color: #333;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.pop-hide {
  display: none;
}

.popup .content {
  width: 639px;
  height: 800px;
  padding: 0px 81px;
  /* overflow: auto; */
  background: #252b38;
  border-radius: 35px;
  border: 2px solid #3e4d6d;
  position: relative;
}

.small-grid-star {
  position: absolute;
  bottom: -100%;
}

::-webkit-scrollbar {
  width: 10px;
  border: 70px solid transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5c6680;
  border-radius: 34px;
  width: 60px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5c6680;
}

::-webkit-scrollbar-button {
  width: 60px;
}

.popup-users {
  background: linear-gradient(
    105.49deg,
    rgba(255, 255, 255, 0.06) -9.46%,
    rgba(255, 255, 255, 0.01) 113.7%
  );
  padding-top: 72px;
  padding-bottom: 72px;
  margin-bottom: 72px;
  border-radius: 30px;
  backdrop-filter: blur(50px);
  height: 350px;
    margin-top: 42px;

}

.close-mobile-popup{
  left: 90%;
  right: auto;
  z-index: 55;
}
