.mobile-participating-container {
  position: absolute;
  background: #1E222E;
  z-index: 5;
  backdrop-filter: blur(30px);
  border-radius: 40px 40px 30px 30px;
  width: 100%;
  height: 144vw;
  right: auto;
  top: 56%;
}

.close-slider{
    margin: 5vw 0px;
}

.mobile-participating-section{
    height: 130vw;
    overflow: scroll;
    z-index: 555;
}


#nice-scroll::-webkit-scrollbar {
    width: 5px;
}
  
  /* Track */
  #nice-scroll::-webkit-scrollbar-track {
    border-radius: 2px;
  }
   
  /* Handle */
  #nice-scroll::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);; 
    border-radius: 2px;
}
  
  /* Handle on hover */
  #nice-scroll::-webkit-scrollbar-thumb:hover {
    background: transparent; 
  }
  .mobile-user-participating{
    font-weight: 600;
    font-weight: 600;
    font-family: 'Mont';
    letter-spacing: 0.035em;
    color: #FFFFFF;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    height: 10vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-inline-start: 10vw;
    margin-inline-end: 10vw;
  }